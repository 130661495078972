.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.empty-img {
  height: 100px;
  width: 100px;
}
